.errorClass{
    font-size: 1rem;
    color: red;
    margin-left: .5rem;
    margin-top: -.5rem;
    margin-bottom: .8rem;
}
.Login {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);
}

.Register{
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);
  
}
.shipping{

    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);

}
.LogCard {
    background: linear-gradient(15deg,rgb(255, 255, 255) 50%,white 50%);
    min-height: 30rem;
    width: 90%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    max-width: 500px;
}

.section{
    margin-left: 4rem;
    margin-right: 4rem;
}

.sectionOTP{
    margin-top: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
}
.form-control{
    height: 4rem !important;
    width: 100% !important;
}
.Input {
    font-size: 1.6rem;
    padding-left: 1rem;
    margin-bottom: 2rem;
    border: 1px solid rgb(172, 169, 169);
    height: 4rem;
    border-radius: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.inputItem{
    display: flex;
    flex-direction: column;
    color: rgb(56, 56, 56);
}

.inputItem > label{
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: .3rem;
   
}
.loginButton{
    width: 100%;
    height: 2.3em;
    background-color: rgb(13, 110, 175);
    border-radius: 7px;
    font-weight: 800;
    color: white;
    letter-spacing: .1rem;
    border: none;
    font-size: 1.5rem;
    transition: 1s;
}
.loginButton:hover{
    cursor: pointer;
    background-color: rgb(32, 32, 238);
    
}
.Heading{
    font-size: 2.5rem;
    margin-top: 1.5rem;
    text-align: center;
    font-weight: 900;
    margin-bottom: 2.5rem;
    

}


textarea:focus, input:focus{
    outline: none;
    border: 1.4px solid blue;
}


/* kooooooooooo */
.addressCard {
    background: white;
    height: 90vh;
    max-width: 85%;
    display: flex;
    flex-direction: row;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

.address{
    min-height: 100vh;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.cardClass{
    height: 500px !important;
}
.checkoutButton{
    
    min-width: max-content;
    width: 20%;
    height: 2.3em;
    background-color: rgb(13, 110, 175);
    border-radius: 7px;
    font-weight: 800;
    color: white;
    letter-spacing: .1rem;
    border: none;
    font-size: 1.5rem;
    transition: 1s;

}
.feilds{
  display: flex;
  flex-direction: column;
}

.InputAddress {
    padding-left: 1rem;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    border: 1px solid rgba(163, 163, 163, 0.384);
    height: 3.5rem;
    border-radius: 5px;
    margin-left: 0rem;
    margin-right: 0rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.addressFeild{
   
    margin-left: 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-right: 1rem;
}
.buttonDiv{

    margin-top: 1rem;
    text-align: center;
    margin-bottom: 2rem;
    height: 3rem !important;
}

/* 
.feilds > label{
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: .3rem;
    margin-left: 0rem;
   
} */
.address_section{
    width: 50%;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y:auto ;

}
.details_section{
    display: flex;
    width: 55%;
    background-color: rgb(243, 241, 241);
    flex-direction: column;
}

.shopingIMG, .pricingShip{
  height: 50%;
}


.shopingIMG{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.shopingIMG > img{
  
    justify-content: center;
    text-align: center;
    max-height: 300px;
}
.pricingHead{
    font-size: large;
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: 5rem;
    text-align: center;
    font-family: sans-serif;
    font-weight: 700;
    letter-spacing: 1.4px;
    color: white;
    background-color: blue;
}
.PricingItems{
    padding-top:2rem;
    padding-left: 5rem;
    font-size: small;
    display: flex;
    flex-direction: column;
 
}

.PricingItem{
    max-width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.PricingItemHead{
    font-size: 1.4rem;
    color: rgb(74, 74, 78);
   
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.PricingItemHeadTotal{
    font-size: large;
    font-weight: 700;
}
.checkboxField{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.2rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    color: rgb(97, 96, 96);
}

.ship_bill{
    font-size: 1.4rem;
    margin-bottom: .9rem;
}
.font_size{
    font-size: small;
}
@media only screen and (max-width: 518px) {
   .details_section{
       display: none;
   }
   .address_section{
    width: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
}
.button_margin_top{
    margin-top: -20%;

}
.PricingItems{

    padding-top: 0rem;
    padding-left: 0rem;
}
.addressCard{
    margin-top: 1rem;
    margin-bottom: 10rem;
    height: 100%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.font_size{
    font-size: 9px;
}
  }
.MuiSvgIcon-root {
    fill: white;
    width: 2em;
    height: 3em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}
  @media only screen and (min-width: 519px) {
    .pricingShipMobile{
    display: none;
 }
 .button_margin_top{
    margin-top: -15%;

}
   }
   @media only screen and (min-width: 750px) {
    .addressCard {
        height: 90vh;
        max-width: 65%;
        display: flex;
        flex-direction: row;
        box-shadow: rgba(32, 32, 32, 0.2) 0px 4px 8px 0px;
        background: white;
        border-radius: 7px;
        overflow: hidden;
        margin-left: auto;
    margin-right: auto;
    }
    .font_size{
    font-size: small;
    }
   }

   .newUser{
       margin-top: 2rem;
       margin-bottom: 1em;
       text-align: end;
       font-size: 1.5rem;
       text-decoration: none;
   }

   .innerClass__single-value {
       font-size: medium;
   }

   .prefetchedAddress{
      
       max-height: auto;
       background-color: rgb(223, 223, 224);
       overflow: auto;
       width: auto;
       margin: 0 1rem;
       z-index: 12;
    margin-bottom: 1rem;
   }
   .listItem{
       cursor: pointer;
       font-size: 1.4rem;
       padding: .5rem;
       border-bottom: 1px solid rgb(22, 56, 165);
       
   }
.orMargin {
    height: 32px;
    margin: 16px 0;
}
.orContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: rgba(38, 38, 45, 0.1);
}
.orContainer .orDivider {
    width: 50%;
    height: 1px;
    border-color: currentcolor rgba(38, 38, 45, 0.1) rgba(38, 38, 45, 0.1);
    border-top: 1px solid rgba(38, 38, 45, 0.1);
}
.orContainer .orCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid rgba(38, 38, 45, 0.1);
    border-radius: 50%;
    width: 34px;
}
.orContainer .orCircle .orText {
    font-size: 11px;
    color: #707c86;
}
.popup {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  /* position: absolute;
  left: 35%;
  right: 35%;
  top: 25%;
  */
  margin: auto;
  background: #FFFFFF;
}