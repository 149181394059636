.homepage-bgimage {
  background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hr_style {
  width: 95%;
  margin: auto;
}
.row_style {
  margin-top: 10px ;
  margin-left: auto;
}


.div_hover { 
  background-color: #FFFFFF; 
}

.div_hover:hover { 
  background-color: #E5E4E2; 
}
.popup {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  /* position: absolute;
  left: 35%;
  right: 35%;
  top: 25%;
  */
  margin: auto;
  background: #FFFFFF;
}
.payment_heading {

font-size: 2rem;
    margin-top: 1.5rem;
    text-align: left;
    font-weight: 900;
    margin-bottom: 2.5rem;
  }
  .Toastify__toast-body {
    margin: auto 0;
    flex: 1 1 auto;
    padding: 6px;
    font-size: medium;
} 
.shipping_order{

    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);

}
.addressCard_order {
    background: white;
    height: 90vh;
    max-width: 85%;
    display: flex;
    flex-direction: row;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    overflow: hidden;

}
.order_web{
  margin-left: 15%; 
  margin-right: -20%;
 }
 .order_hr{
  margin-left: -18%;
 }
 .mousechange:hover {
  cursor: pointer;
}
.order_list{
  width: 100%;
  min-height: 100vh;
  /*background: white; */
  background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);

}

.order_details_outer{
 width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);
  }
.logo_align{
  margin-left: 520px;
}

@media only screen and (min-width: 750px) {
  
    .addressCard_order {
        height: 90vh;
        max-width: 65%;
        display: flex;
        flex-direction: row;
        box-shadow: rgba(32, 32, 32, 0.2) 0px 4px 8px 0px;
        background: white;
        border-radius: 7px;
        overflow: hidden;
    }
   }
  @media only screen and (min-width: 992px) {
    .order_mobile{
    display: none;
 }
}


 .order_details{
 /* margin-top: -35%; */
}
@media only screen and (max-width: 518px) {
  .order_web{
 display:none;
 }
  .order_mobile{
    /* width: fit-content;
    margin-right: auto;
    margin-left: 15%; */
    margin-left: -5%;
   /* margin-top: -80%
    margin-top: -90%;*/
 }
.addressCard_order{
/*    margin-top: 10rem;
    margin-bottom: 2rem;*/
    height: 100px;
    border-radius: 0px;
    max-width: 90%
}
.card_style{
  /*margin-left: 8%;
    margin-right: 2px;
    margin-top: 10px; 
    width: 82%;*/

    margin-left: 6%;
    margin-right: 3px;

  }
   .no_data{
      margin-left: -10%;
      margin-top: -100%;

    }
  .order_details{
/*  margin-top: -780px;
  margin-left: 5px; */
} 
.logo_align{
  margin-left: 80px;
}
.order_details_outer{
  width: 100%;
  min-height: 100vh;
  background: white;

  }
.order_list{
  width: 100%;
  min-height: 100vh;
  background: white; 

}
}
@media only screen and (max-width: 991px) and (min-width: 519px){
  .order_web{
 display:none;
 }
  .order_mobile{
    width:fit-content;

/*margin-right: -40px;
margin-top: -90%;*/
 }
.addressCard_order{
   /* margin-top: 10rem;
    margin-bottom: 2rem;*/
   height: 100px;
    border-radius: 0px;
    max-width: 90%
}
.card_style{
  margin-left: 10%;
  margin-right: 10%;
 margin-bottom: 1%;
  }
  .order_hr{
  margin-left: 10%;
 }
}
/*   .centerDiv
    {
      width: 400%;
      height:200px;
margin-left: -150%;      
background-color:#FFA500 ;
    }
    .div1
    {
      width: 33%;
      height:200px;
      background-color:white ;
      float:left;
    }
    .div2
    {
      width: 34%;
      height:200px;
      background-color:#FFA500 ;
      float:left;

    }*/
    .no_data{
      margin-left: -70%;
    }
   
   .loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}