@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);

.logo{
 width: 10%;
 display: block;
 margin-top: 10px;
 margin-left: 20px;

 }
.label_size{
	font-size: medium;
}
.quantity_style{
 margin-top: 2%;
 margin-left:130px;
 margin-right:auto
}
.quantity_label_style{
    margin-right: 10px;
}
.size_label_style{
margin-right: 10px;
}
.size_style{
 margin-top: 2%;
 margin-left:160px;
 margin-right:auto
}
.merchant_logo{

    display: block;
    max-width: 200px;
    max-height: 200px;

}
.hearder_display{
display: flex;
}
.hearder_display_mobile{
	display:none;
}
.logo_style_thumb{
  margin-left: 0px;
}
@media only screen and (max-width: 518px) {
.logo{
  width: 25%;
  display: block;
  margin-top: 10px;
  margin-left: 60px;
 }
 .product_details_title{

    margin-left: 5%;

}
.logo_style_thumb{
  margin-left: -40px;
}
.merchant_logo{

    display: block;
    max-width: 200px;
    max-height: 200px;

}
.quantity_style{
 margin-top: 2%;
 margin-left:auto;
 margin-right:auto
}
.quantity_label_style{
    margin-right: 10px;
    margin-left: 50px;
}
.size_style{
 margin-top: 2%;
 margin-left:auto;
 margin-right:auto
}
.size_label_style{
margin-left: 70px;
margin-right: 10px;
}
.hearder_display{
	display:none;
}
.hearder_display_mobile{
display: flex;
}
}
.errorClass{
    font-size: 1rem;
    color: red;
    margin-left: .5rem;
    margin-top: -.5rem;
    margin-bottom: .8rem;
}
.Login {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);
}

.Register{
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);
  
}
.shipping{

    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);

}
.LogCard {
    background: linear-gradient(15deg,rgb(255, 255, 255) 50%,white 50%);
    min-height: 30rem;
    width: 90%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    max-width: 500px;
}

.section{
    margin-left: 4rem;
    margin-right: 4rem;
}

.sectionOTP{
    margin-top: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
}
.form-control{
    height: 4rem !important;
    width: 100% !important;
}
.Input {
    font-size: 1.6rem;
    padding-left: 1rem;
    margin-bottom: 2rem;
    border: 1px solid rgb(172, 169, 169);
    height: 4rem;
    border-radius: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.inputItem{
    display: flex;
    flex-direction: column;
    color: rgb(56, 56, 56);
}

.inputItem > label{
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: .3rem;
   
}
.loginButton{
    width: 100%;
    height: 2.3em;
    background-color: rgb(13, 110, 175);
    border-radius: 7px;
    font-weight: 800;
    color: white;
    letter-spacing: .1rem;
    border: none;
    font-size: 1.5rem;
    transition: 1s;
}
.loginButton:hover{
    cursor: pointer;
    background-color: rgb(32, 32, 238);
    
}
.Heading{
    font-size: 2.5rem;
    margin-top: 1.5rem;
    text-align: center;
    font-weight: 900;
    margin-bottom: 2.5rem;
    

}


textarea:focus, input:focus{
    outline: none;
    border: 1.4px solid blue;
}


/* kooooooooooo */
.addressCard {
    background: white;
    height: 90vh;
    max-width: 85%;
    display: flex;
    flex-direction: row;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

.address{
    min-height: 100vh;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.cardClass{
    height: 500px !important;
}
.checkoutButton{
    
    min-width: -webkit-max-content;
    
    min-width: max-content;
    width: 20%;
    height: 2.3em;
    background-color: rgb(13, 110, 175);
    border-radius: 7px;
    font-weight: 800;
    color: white;
    letter-spacing: .1rem;
    border: none;
    font-size: 1.5rem;
    transition: 1s;

}
.feilds{
  display: flex;
  flex-direction: column;
}

.InputAddress {
    padding-left: 1rem;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    border: 1px solid rgba(163, 163, 163, 0.384);
    height: 3.5rem;
    border-radius: 5px;
    margin-left: 0rem;
    margin-right: 0rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.addressFeild{
   
    margin-left: 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-right: 1rem;
}
.buttonDiv{

    margin-top: 1rem;
    text-align: center;
    margin-bottom: 2rem;
    height: 3rem !important;
}

/* 
.feilds > label{
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: .3rem;
    margin-left: 0rem;
   
} */
.address_section{
    width: 50%;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y:auto ;

}
.details_section{
    display: flex;
    width: 55%;
    background-color: rgb(243, 241, 241);
    flex-direction: column;
}

.shopingIMG, .pricingShip{
  height: 50%;
}


.shopingIMG{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.shopingIMG > img{
  
    justify-content: center;
    text-align: center;
    max-height: 300px;
}
.pricingHead{
    font-size: large;
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: 5rem;
    text-align: center;
    font-family: sans-serif;
    font-weight: 700;
    letter-spacing: 1.4px;
    color: white;
    background-color: blue;
}
.PricingItems{
    padding-top:2rem;
    padding-left: 5rem;
    font-size: small;
    display: flex;
    flex-direction: column;
 
}

.PricingItem{
    max-width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.PricingItemHead{
    font-size: 1.4rem;
    color: rgb(74, 74, 78);
   
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.PricingItemHeadTotal{
    font-size: large;
    font-weight: 700;
}
.checkboxField{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.2rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    color: rgb(97, 96, 96);
}

.ship_bill{
    font-size: 1.4rem;
    margin-bottom: .9rem;
}
.font_size{
    font-size: small;
}
@media only screen and (max-width: 518px) {
   .details_section{
       display: none;
   }
   .address_section{
    width: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
}
.button_margin_top{
    margin-top: -20%;

}
.PricingItems{

    padding-top: 0rem;
    padding-left: 0rem;
}
.addressCard{
    margin-top: 1rem;
    margin-bottom: 10rem;
    height: 100%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.font_size{
    font-size: 9px;
}
  }
.MuiSvgIcon-root {
    fill: white;
    width: 2em;
    height: 3em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -webkit-user-select: none;
            user-select: none;
}
  @media only screen and (min-width: 519px) {
    .pricingShipMobile{
    display: none;
 }
 .button_margin_top{
    margin-top: -15%;

}
   }
   @media only screen and (min-width: 750px) {
    .addressCard {
        height: 90vh;
        max-width: 65%;
        display: flex;
        flex-direction: row;
        box-shadow: rgba(32, 32, 32, 0.2) 0px 4px 8px 0px;
        background: white;
        border-radius: 7px;
        overflow: hidden;
        margin-left: auto;
    margin-right: auto;
    }
    .font_size{
    font-size: small;
    }
   }

   .newUser{
       margin-top: 2rem;
       margin-bottom: 1em;
       text-align: end;
       font-size: 1.5rem;
       text-decoration: none;
   }

   .innerClass__single-value {
       font-size: medium;
   }

   .prefetchedAddress{
      
       max-height: auto;
       background-color: rgb(223, 223, 224);
       overflow: auto;
       width: auto;
       margin: 0 1rem;
       z-index: 12;
    margin-bottom: 1rem;
   }
   .listItem{
       cursor: pointer;
       font-size: 1.4rem;
       padding: .5rem;
       border-bottom: 1px solid rgb(22, 56, 165);
       
   }
.orMargin {
    height: 32px;
    margin: 16px 0;
}
.orContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: rgba(38, 38, 45, 0.1);
}
.orContainer .orDivider {
    width: 50%;
    height: 1px;
    border-color: currentcolor rgba(38, 38, 45, 0.1) rgba(38, 38, 45, 0.1);
    border-top: 1px solid rgba(38, 38, 45, 0.1);
}
.orContainer .orCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid rgba(38, 38, 45, 0.1);
    border-radius: 50%;
    width: 34px;
}
.orContainer .orCircle .orText {
    font-size: 11px;
    color: #707c86;
}
.popup {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  /* position: absolute;
  left: 35%;
  right: 35%;
  top: 25%;
  */
  margin: auto;
  background: #FFFFFF;
}
.homepage-bgimage {
  background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hr_style {
  width: 95%;
  margin: auto;
}
.row_style {
  margin-top: 10px ;
  margin-left: auto;
}
.mousechange:hover {
  cursor: pointer;
}

.div_hover { 
  background-color: #FFFFFF; 
}

.div_hover:hover { 
  background-color: #E5E4E2; 
}
.popup {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  /* position: absolute;
  left: 35%;
  right: 35%;
  top: 25%;
  */
  margin: auto;
  background: #FFFFFF;
}
.payment_heading {

font-size: 2rem;
    margin-top: 1.5rem;
    text-align: left;
    font-weight: 900;
    margin-bottom: 2.5rem;
  }
  .Toastify__toast-body {
    margin: auto 0;
    flex: 1 1 auto;
    padding: 6px;
    font-size: medium;
} 
@media only screen and (max-width: 518px) {

.success_style{
   width: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
}
}
.success_style{
width:10%;
 height:50 ;
 display: block;
 margin-left: auto;
 margin-right: auto;
}
/* Basic Layout
---------------------------------------------------------------- */
* {
  box-sizing: border-box;
}

/* body {
  background: #ebf1f5;
  font-family: sans-serif;
  font-size: 100%;
  color: #555;
  margin: 0;
  padding: 0;
}
*/
.spf-form {
  background: #fff;
/*  box-shadow: rgba(0,0,0,.15) 0 2px 20px,
              rgba(0,0,0,.1) 0 0 3px;*/
  border-radius: 6px;
  min-width: 320px;
  max-width: 550px;
  width: 95%;
  padding: 1px;
 /* margin: 2em auto; */
}

fieldset {
  border: none;
  margin: 0;
  padding: 3em 1em 2em;
  border-bottom: 1px solid #d2d8dc;
}

.spf-field-submit {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 2.5em;
}


.spf-field {
  display: inline-block;
  width: 100%;
  margin-bottom: 1em;
}

.label {
  display: block;
  margin-bottom: .25em;
  white-space: nowrap;
  overflow: hidden;
  font-size:medium;
}

.spf-label-secondary {
  color: #999;
  font-size: .8125em;
  margin-top: .5em;
}

.spf-input-text {
  display: block;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: .65em .5em;
  font-size: medium;
}


/* Specific Fields
---------------------------------------------------------------- */

/* Name */

.spf-fs-name .spf-field {
/*  width: 48%;*/
  margin-right: 2%;
}

.spf-field-lname {
  margin-right: 0;
  width: 42%;
  margin-left: 10px;
}
.spf-field-fname {
  width: 48%;
}
/* Address */
.spf-field-discount {
    width: 78%;
    
}
.spf-field-discount-arrow {

margin-right: 0;
    width: 12%;
    margin-left: 10px;
   } 
.spf-field-city {
  width: 41%;
  margin-right: 2%;
}

.spf-field-state {
  width: 15%;
  margin-right: 2%;
}

.spf-field-postcode {
  width: 25%;
}

/* Iframe divs */
.spf-field-cc{
  height:50px;
}

.spf-number{
  width:67%;
}

.spf-verification_value{
  width:30%;
}


/* Expiry Date */

.spf-field-group {
  display: inline-block;
}

.spf-month {
  width: 20%;
}

.spf-year {
  width: 30%;
}

.spf-exp-divider {
  display: inline-block;
  margin: 0 .4em;
  font-size: 150%;
  line-height: 1.5;
  position: relative;
  top: .2em;
}


/* Button */

.new_payment {
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  background-color: rgb(13, 110, 175);
  color: #fff;
  padding: .75em 2em;
  font-size: 100%;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;

}


/* Message */

#errors,
#message {
  color: red;
  margin: 1em 0;
  line-height: 1.5;
}

#message {
  color: green;
}


/* Small Screen Modifications*/

@media screen and (max-width: 400px) {
  .spf-form {
    margin: 0 auto;
  }
}
@media screen and (max-width: 625px) {
  .spf-form {
    width: 80%;
  }
}

.apple-pay-button {
	width: 209px;
	height: 40px;
  margin-left:3rem;
	-webkit-appearance: -apple-pay-button;
	cursor: pointer;
}

.homepage-bgimage {
  background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hr_style {
  width: 95%;
  margin: auto;
}
.row_style {
  margin-top: 10px ;
  margin-left: auto;
}


.div_hover { 
  background-color: #FFFFFF; 
}

.div_hover:hover { 
  background-color: #E5E4E2; 
}
.popup {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  /* position: absolute;
  left: 35%;
  right: 35%;
  top: 25%;
  */
  margin: auto;
  background: #FFFFFF;
}
.payment_heading {

font-size: 2rem;
    margin-top: 1.5rem;
    text-align: left;
    font-weight: 900;
    margin-bottom: 2.5rem;
  }
  .Toastify__toast-body {
    margin: auto 0;
    flex: 1 1 auto;
    padding: 6px;
    font-size: medium;
} 
.shipping_order{

    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);

}
.addressCard_order {
    background: white;
    height: 90vh;
    max-width: 85%;
    display: flex;
    flex-direction: row;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    overflow: hidden;

}
.order_web{
  margin-left: 15%; 
  margin-right: -20%;
 }
 .order_hr{
  margin-left: -18%;
 }
 .mousechange:hover {
  cursor: pointer;
}
.order_list{
  width: 100%;
  min-height: 100vh;
  /*background: white; */
  background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);

}

.order_details_outer{
 width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(15deg,rgb(179, 206, 255) 50%,rgb(79, 78, 112) 50%);
  }
.logo_align{
  margin-left: 520px;
}

@media only screen and (min-width: 750px) {
  
    .addressCard_order {
        height: 90vh;
        max-width: 65%;
        display: flex;
        flex-direction: row;
        box-shadow: rgba(32, 32, 32, 0.2) 0px 4px 8px 0px;
        background: white;
        border-radius: 7px;
        overflow: hidden;
    }
   }
  @media only screen and (min-width: 992px) {
    .order_mobile{
    display: none;
 }
}


 .order_details{
 /* margin-top: -35%; */
}
@media only screen and (max-width: 518px) {
  .order_web{
 display:none;
 }
  .order_mobile{
    /* width: fit-content;
    margin-right: auto;
    margin-left: 15%; */
    margin-left: -5%;
   /* margin-top: -80%
    margin-top: -90%;*/
 }
.addressCard_order{
/*    margin-top: 10rem;
    margin-bottom: 2rem;*/
    height: 100px;
    border-radius: 0px;
    max-width: 90%
}
.card_style{
  /*margin-left: 8%;
    margin-right: 2px;
    margin-top: 10px; 
    width: 82%;*/

    margin-left: 6%;
    margin-right: 3px;

  }
   .no_data{
      margin-left: -10%;
      margin-top: -100%;

    }
  .order_details{
/*  margin-top: -780px;
  margin-left: 5px; */
} 
.logo_align{
  margin-left: 80px;
}
.order_details_outer{
  width: 100%;
  min-height: 100vh;
  background: white;

  }
.order_list{
  width: 100%;
  min-height: 100vh;
  background: white; 

}
}
@media only screen and (max-width: 991px) and (min-width: 519px){
  .order_web{
 display:none;
 }
  .order_mobile{
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;

/*margin-right: -40px;
margin-top: -90%;*/
 }
.addressCard_order{
   /* margin-top: 10rem;
    margin-bottom: 2rem;*/
   height: 100px;
    border-radius: 0px;
    max-width: 90%
}
.card_style{
  margin-left: 10%;
  margin-right: 10%;
 margin-bottom: 1%;
  }
  .order_hr{
  margin-left: 10%;
 }
}
/*   .centerDiv
    {
      width: 400%;
      height:200px;
margin-left: -150%;      
background-color:#FFA500 ;
    }
    .div1
    {
      width: 33%;
      height:200px;
      background-color:white ;
      float:left;
    }
    .div2
    {
      width: 34%;
      height:200px;
      background-color:#FFA500 ;
      float:left;

    }*/
    .no_data{
      margin-left: -70%;
    }
   
   .loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  -webkit-animation: load8 1.1s infinite linear;
          animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.apple-pay-button {
	width: 250px;
	height: 40px;

	-webkit-appearance: -apple-pay-button;
	cursor: pointer;
}
/* INITIALIZERS + DEFAULTS
 * ============================== */

*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	font-size: 65%;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	
}

img {
	display: block;
	max-width: 100%;
	max-height: 100%;
}

h1 {
	font-weight: 300;
	margin: 0 0 15px;
	font-size: 3rem;
}

h2 {
	font-weight: 300;
	margin: 0;
	font-size: 2rem;
}

/* BASE APP
  * ============================== */
.App__header {
	background-color: #222;
	background-image: url("https://unsplash.it/1000/300?image=823");
	background-size: cover;
	color: white;
	padding: 10px 10px;
}

.App__nav {
	width: 100%;
	list-style: none;
}

.App__customer-actions {
	float: left;
	padding: 10px;
}

.App__title {
	padding: 80px 20px;
	text-align: center;
}

.Product-wrapper {
	max-width: 900px;
	margin: 40px auto 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.App__view-cart-wrapper {
	float: right;
}

.App__view-cart-wrapper2 {
	position: absolute;
	top: 10px;
	left: -65px;
}

.App__view-cart {
	font-size: 45px;
	border: none;
	background: none;
	cursor: pointer;
	color: grey;
}

.button {
	background-color: #2752ff;
	color: white;
	border: none;
	font-size: 1.2rem;
	padding: 10px 17px;
	cursor: pointer;
}

.button:hover,
.button:focus {
	background-color: #222222;
}

.button:disabled {
	background: #bfbfbf;
	cursor: not-allowed;
}

.login {
	font-size: 1.2rem;
	color: #b8b8b8;
	cursor: pointer;
}

.login:hover {
	color: white;
}

.Flash__message-wrapper {
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	align-items: flex-end;
	justify-content: center;
	position: fixed;
	bottom: 0;
	pointer-events: none;
	z-index: 227;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.Flash__message {
	background: rgba(0, 0, 0, 0.88);
	border-radius: 3px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	color: #ffffff;
	cursor: default;
	display: -ms-flexbox;
	display: none;
	pointer-events: auto;
	position: relative;
	font-size: 20px;
	line-height: 28px;
	font-weight: 400;
	padding: 10px 20px;
	margin: 0;
}
.Your_cart{
	min-height: 100vh;
}
.Flash__message--open {
	display: flex;
}

/* CART
  * ============================== */
.Cart {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	width: 350px;
	background-color: white;
	display: flex;
	flex-direction: column;
	border-left: 1px solid #e5e5e5;
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
	transition: -webkit-transform 0.15s ease-in-out;
	transition: transform 0.15s ease-in-out;
	transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
	z-index: 1;
}

.Cart--open {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.Cart__close {
	position: absolute;
	right: 9px;
	top: 8px;
	font-size: 35px;
	color: #999;
	border: none;
	background: transparent;
	transition: -webkit-transform 100ms ease;
	transition: transform 100ms ease;
	transition: transform 100ms ease, -webkit-transform 100ms ease;
	cursor: pointer;
}

.Cart__header {
	padding: 20px;
	border-bottom: 1px solid #e5e5e5;
	flex: 0 0 auto;
	display: inline-block;
}

.Cart__line-items {
	/* flex: 1 0 auto; */
	margin: 0;
	padding: 20px; 
}

.Cart__footer {
	padding: 20px;
	border-top: 1px solid #e5e5e5;
	flex: 0 0 auto;
	left: 0;
	right: 0;
	bottom: 0;
}

.Cart__checkout {
	margin-top: 20px;
	display: block;
	width: 100%;
}

.Cart-info {
	padding: 2px 0px 6px;
}

.Cart-info__total {
	float: left;
	text-transform: uppercase;
}

.Cart-info__small {
	font-size: 11px;
}

.Cart-info__pricing {
	float: right;
}

.pricing {
	margin-left: 5px;
	font-size: 16px;
	color: black;
}

/* LINE ITEMS
  * ============================== */
.Line-item {
	margin-bottom: 3rem;
	overflow: hidden;
	-webkit-backface-visibility: visible;
	        backface-visibility: visible;
	min-height: 65px;
	position: relative;
	opacity: 1;
	transition: opacity 0.2s ease-in-out;
}

.Line-item__img {
	width: 60px;
	height: 70px;
	border-radius: 3px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: #e5e5e5;
	position: absolute;
}

.Line-item__content {
	width: 100%;
	padding-left: 75px;
}

.Line-item__content-row {
	display: inline-block;
	width: 100%;
	margin-bottom: 2rem;
	position: relative;
	
}

.Line-item__variant-title {
	float: right;
	font-weight: bold;
	font-size: 11px;
	line-height: 17px;
	color: #767676;
}

.Line-item__title {
	color: #4e5665;
	font-size: 1.6rem;
	font-weight: 600;
	margin-top: 30px;
}

.Line-item__price {
	line-height: 23px;
	float: right;
	font-weight: bold;
	font-size: 15px;
	margin-right: 40px;
}

.Line-item__quantity-container {
	border: 1px solid #0051ff;
	float: left;
	border-radius: 5px;
}

.Line-item__quantity-update {
	color: #767676;
	display: block;
	float: left;
	height: 21px;
	line-height: 16px;
	font-family: monospace;
	width: 25px;
	padding: 0;
	border: none;
	background: transparent;
	box-shadow: none;
	cursor: pointer;
	font-size: 18px;
	text-align: center;
}

.Line-item__quantity-update-form {
	display: inline;
}

.Line-item__quantity {
	color: black;
	width: 38px;
	height: 21px;
	line-height: 23px;
	font-size: 15px;
	border: none;
	text-align: center;
	-moz-appearance: textfield;
	background: transparent;
	border-left: 1px solid #767676;
	border-right: 1px solid #767676;
	display: block;
	float: left;
	padding: 0;
	border-radius: 0;
}

.Line-item__remove {
	position: absolute;
	right: 0;
	top: 0;
	border: 0;
	background: 0;
	font-size: 26px;
	top: -4px;
	opacity: 0.5;
	color: red;
}

.Line-item__remove:hover {
	opacity: 1;
	cursor: pointer;
}

/* PRODUCTS
  * ============================== */
.Product {
	flex: 0 1 31%;
	margin-left: 1%;
	margin-right: 1%;
	margin-bottom: 3%;
}

.Product__title {
	font-size: 1.3rem;
	margin-top: 1rem;
	margin-bottom: 0.4rem;
	opacity: 0.7;
}

.Product__price {
	display: block;
	font-size: 1.1rem;
	opacity: 0.5;
	margin-bottom: 0.4rem;
}

.Product__option {
	display: block;
	width: 100%;
	margin-bottom: 10px;
}

.Product__quantity {
	display: block;
	width: 15%;
	margin-bottom: 10px;
}

/* SINGLE PRODUCT VIEW
	* ============================== */
.Product-wrapper2 {
	max-width: 900px;
	margin: 60px auto 0;
	justify-content: center;
	align-items: center;
}

.Images {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* width: 20%; */
}

.homeButton {
	position: absolute;
	top: 10px;
	left: 50%;
}

.Product__description {
	font-size: 20px;
}

.Product__title2 {
	font-size: 30px;
}

.Product__info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
    padding-bottom: 4rem;

}

/* CUSTOMER AUTH
  * ============================== */
.CustomerAuth {
	background: #2a2c2e;
	display: none;
	height: 100%;
	left: 0;
	opacity: 0;
	padding: 0 0 65px;
	top: 0;
	width: 100%;
	text-align: center;
	color: #fff;
	transition: opacity 150ms;
	opacity: 1;
	visibility: visible;
	z-index: 1000;
	position: fixed;
}

.CustomerAuth--open {
	display: block;
}

.CustomerAuth__close {
	position: absolute;
	right: 9px;
	top: 8px;
	font-size: 35px;
	color: #999;
	border: none;
	background: transparent;
	transition: -webkit-transform 100ms ease;
	transition: transform 100ms ease;
	transition: transform 100ms ease, -webkit-transform 100ms ease;
	cursor: pointer;
}

.CustomerAuth__body {
	padding: 130px 30px;
	width: 700px;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	position: relative;
}

.CustomerAuth__heading {
	font-size: 24px;
	font-weight: 500;
	padding-bottom: 15px;
}

.CustomerAuth__credential {
	display: block;
	position: relative;
	margin-bottom: 15px;
	border-radius: 3px;
}

.CustomerAuth__input {
	height: 60px;
	padding: 24px 10px 20px;
	border: 0px;
	font-size: 18px;
	background: #fff;
	width: 100%;
}

.CustomerAuth__submit {
	float: right;
}

.error {
	display: block;
	font-size: 15px;
	padding: 10px;
	position: relative;
	min-height: 2.64286em;
	background: #fbefee;
	color: #c23628;
}

.slider_classs{
	padding-left: 4rem;
	padding-right: 4rem;
	width: 500px;
}
.carousel .thumbs{
	display: none;
}
.slider_items{
	display: flex;
	justify-content: center;
}
@media only screen and (min-width: 1000px) {

		.Product-wrapper2 {
			max-width: 900px;
			margin: 60px auto 0;
			justify-content: center;
			align-items: center;
			display: flex;
		}
		
  }
